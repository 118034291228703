<template>
  <div>
    <!-- 联赛 -->
    <el-form
      v-loading="loading"
      label-width="200px"
      :model="dataForm"
      :rules="rules"
      ref="dataForm"
      class="demo-ruleForm"
    >
      <el-form-item label="赛事" style="margin-bottom: 35px">
        <el-col :span="6">
          {{ match_data.match_name }}
        </el-col>
      </el-form-item>

      <el-form-item label="比赛时间" required label-width="200px">
        <el-col :span="5">
          <el-form-item prop="startTime" id="startTime">
            <el-date-picker
              :readonly="has_stage"
              ref="match_time_startTime"
              type="datetime"
              placeholder="选择比赛开始时间"
              v-model="dataForm.startTime"
              style="width: 100%;"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col class="line" :span="1" style="text-align: center">-</el-col>
        <el-col :span="5" id="endTime">
          <el-form-item prop="endTime">
            <el-date-picker
              :readonly="has_stage"
              ref="match_time_endTime"
              type="datetime"
              placeholder="选择比赛结束时间"
              v-model="dataForm.endTime"
              style="width: 100%;"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-form-item>

      <el-form-item
        label="门派奖励"
        label-width="200px"
        prop="teamReward.reward"
        id="quillEditorQiniu"
      >
        <!-- 基于elementUi的上传组件 el-upload begin-->
        <el-upload
          class="avatar-uploader"
          :accept="'image/*'"
          :show-file-list="false"
          action="#"
          style="height: 0;"
          :http-request="httpUploadEditorRequest"
        >
        </el-upload>
        <!-- 基于elementUi的上传组件 el-upload end-->
        <quill-editor
          class="editor ql-editor"
          v-model="dataForm.teamReward.reward"
          ref="customQuillEditor"
          :options="editorOption"
        >
        </quill-editor>
      </el-form-item>

      <el-form-item
        label="个人奖励"
        label-width="200px"
        prop="personReword.reward"
        id="quillEditorQiniuPerson"
      >
        <!-- 基于elementUi的上传组件 el-upload begin-->
        <el-upload
          class="avatar-uploader"
          :accept="'image/*'"
          :show-file-list="false"
          action="#"
          style="height: 0;"
          :http-request="httpUploadEditorRequest2"
        >
        </el-upload>
        <!-- 基于elementUi的上传组件 el-upload end-->
        <quill-editor
          class="editor ql-editor"
          v-model="dataForm.personReword.reward"
          ref="personQuillEditorPrize"
          :options="personOptionPrize"
        >
        </quill-editor>
      </el-form-item>

      <el-form-item
        label="积分规则"
        label-width="200px"
        prop="scoreRule.rule"
        id="scoreEditorQiniuPrize"
      >
        <!-- 基于elementUi的上传组件 el-upload begin-->
        <el-upload
          class="avatar-uploader"
          :accept="'image/*'"
          :show-file-list="false"
          action="#"
          style="height: 0;"
          :http-request="httpUploadEditorRequest1"
        >
        </el-upload>
        <!-- 基于elementUi的上传组件 el-upload end-->
        <quill-editor
          class="editor ql-editor"
          v-model="dataForm.scoreRule.rule"
          ref="customQuillEditorPrize"
          :options="editorOptionPrize"
        >
        </quill-editor>
      </el-form-item>

      <el-form-item style="margin-top: 50px;">
        <perButton
          type="primary"
          perm="armory:reporter:save"
          @click="submitForm()"
          size="large"
          >提交</perButton
        >
        <el-button size="large" @click="goBack()">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import perButton from "@/components/perm/perButton";
import { randomStr } from "@/utils/randomStr";
import { uploadOssUrl } from "@/utils/uploadUrl";
import { toolbarOptions } from "./public.js/toolbarOptions";
import { mapActions, mapState } from "vuex";
export default {
  name: "manager",
  components: {
    perButton,
  },
  computed: {
    ...mapState("match", ["match_types"]),
    isEdit: function() {
      if (this.view_type == "edit") {
        return true;
      }

      return false;
    },
  },
  data() {
    let self = this;

    const matchEndTimeCheckRule = (rule, value, callback) => {
      if (self.dataForm.startTime != null && self.dataForm.endTime != null) {
        let startTime = new Date(self.dataForm.startTime);
        let endTime = new Date(self.dataForm.endTime);
        if (endTime <= startTime) {
          callback(new Error("结束时间必须大于开始时间"));
          return;
        }
      }

      callback();
    };

    return {
      match_data: {
        match_name: "",
      },
      match_type: "",
      match_subject_id: "",

      view_type: "edit",
      type: "s1",
      has_stage: false,
      org_rule: "",
      org_score: "",
      org_prize: "",
      dataForm: {
        _id: "",
        startTime: null,
        endTime: null,
        teamReward: {
          reward: "",
          reward_url: "",
        },
        personReword: {
          reward: "",
          reward_url: "",
        },
        scoreRule: {
          rule: "",
          rule_url: "",
        },
      },
      rules: {
        startTime: [
          {
            type: "date",
            required: true,
            message: "请选择比赛开始日期",
            trigger: ["change", "blur"],
          },
          { validator: matchEndTimeCheckRule, trigger: ["change", "blur"] },
        ],
        endTime: [
          {
            type: "date",
            required: true,
            message: "请选择比赛结束日期",
            trigger: ["change"],
          },
          { validator: matchEndTimeCheckRule, trigger: ["change"] },
        ],
        "teamReward.reward": [
          {
            required: true,
            message: "请输入门派奖励",
            trigger: ["blur", "change"],
          },
        ],
        "personReword.reward": [
          {
            required: true,
            message: "请输入个人奖励",
            trigger: ["blur", "change"],
          },
        ],
        "scoreRule.rule": [
          {
            required: true,
            message: "请输入积分规则",
            trigger: ["blur", "change"],
          },
        ],
      },
      editorOption: {
        placeholder: "请填写赛事规则",
        modules: {
          imageResize: {
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white",
            },
            modules: ["Resize", "DisplaySize", "Toolbar"],
          },
          toolbar: {
            container: toolbarOptions, // 工具栏
            handlers: {
              size: function(value) {
                if (value === "custom-size") {
                  value = prompt("请输入字体大小");
                  if (
                    !/^(([2-6][0-9])|([7][0-2])|([1][8-9]))(rpx)$/.test(value)
                  ) {
                    alert(
                      "请输入合法的字体大小，使用rpx单位， 大小在18rpx-72rpx之间, 1rpx=2px， 用于适配不同设备"
                    );
                    value = "";
                  }
                }

                this.quill.format("size", value);
              },
              color: function(value) {
                if (value === "custom-color") {
                  value = prompt("请输入十六进制的颜色");
                  if (!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(value)) {
                    alert("请输入合法的十六进制颜色");
                    value = "";
                  }
                }

                this.quill.format("color", value);
              },
              image: function(value) {
                if (value) {
                  document
                    .querySelector("#quillEditorQiniu .avatar-uploader input")
                    .click();
                } else {
                  this.quill.format("image", false);
                }
              },
              video: function(value) {
                if (value) {
                  document
                    .querySelector("#quillEditorQiniu .avatar-uploader input")
                    .click();
                } else {
                  this.quill.format("video", false);
                }
              },
            },
          },
        },
      },
      editorOptionPrize: {
        placeholder: "请填写赛事奖励",
        modules: {
          imageResize: {
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white",
            },
            modules: ["Resize", "DisplaySize", "Toolbar"],
          },
          toolbar: {
            container: toolbarOptions, // 工具栏
            handlers: {
              size: function(value) {
                if (value === "custom-size") {
                  value = prompt("请输入字体大小");
                  if (
                    !/^(([2-6][0-9])|([7][0-2])|([1][8-9]))(rpx)$/.test(value)
                  ) {
                    alert(
                      "请输入合法的字体大小，使用rpx单位， 大小在18rpx-72rpx之间, 1rpx=2px， 用于适配不同设备"
                    );
                    value = "";
                  }
                }

                this.quill.format("size", value);
              },
              color: function(value) {
                if (value === "custom-color") {
                  value = prompt("请输入十六进制的颜色");
                  if (!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(value)) {
                    alert("请输入合法的十六进制颜色");
                    value = "";
                  }
                }

                this.quill.format("color", value);
              },
              image: function(value) {
                if (value) {
                  document
                    .querySelector(
                      "#scoreEditorQiniuPrize .avatar-uploader input"
                    )
                    .click();
                } else {
                  this.quill.format("image", false);
                }
              },
              video: function(value) {
                if (value) {
                  document
                    .querySelector(
                      "#scoreEditorQiniuPrize .avatar-uploader input"
                    )
                    .click();
                } else {
                  this.quill.format("video", false);
                }
              },
            },
          },
        },
      },
      personOptionPrize: {
        placeholder: "请填写奖励",
        modules: {
          imageResize: {
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white",
            },
            modules: ["Resize", "DisplaySize", "Toolbar"],
          },
          toolbar: {
            container: toolbarOptions, // 工具栏
            handlers: {
              size: function(value) {
                if (value === "custom-size") {
                  value = prompt("请输入字体大小");
                  if (
                    !/^(([2-6][0-9])|([7][0-2])|([1][8-9]))(rpx)$/.test(value)
                  ) {
                    alert(
                      "请输入合法的字体大小，使用rpx单位， 大小在18rpx-72rpx之间, 1rpx=2px， 用于适配不同设备"
                    );
                    value = "";
                  }
                }

                this.quill.format("size", value);
              },
              color: function(value) {
                if (value === "custom-color") {
                  value = prompt("请输入十六进制的颜色");
                  if (!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(value)) {
                    alert("请输入合法的十六进制颜色");
                    value = "";
                  }
                }

                this.quill.format("color", value);
              },
              image: function(value) {
                if (value) {
                  document
                    .querySelector(
                      "#quillEditorQiniuPerson .avatar-uploader input"
                    )
                    .click();
                } else {
                  this.quill.format("image", false);
                }
              },
              video: function(value) {
                if (value) {
                  document
                    .querySelector(
                      "#quillEditorQiniuPerson .avatar-uploader input"
                    )
                    .click();
                } else {
                  this.quill.format("video", false);
                }
              },
            },
          },
        },
      },
      loading: false,
    };
  },
  mounted() {
    this.match_subject_id = this.$route.query.match_subject_id;
    // this.setFormData();
    this.getDetail();
  },

  methods: {
    async getDetail() {
      let res = await this.$http.match_subject.subject_list();
      if (res.code == 0) {
        if (res.data.length != 0) {
          res.data.forEach((item) => {
            if (item._id == this.match_subject_id) {
              this.match_data = item;
              this.match_type = item.match_type;
              this.setFormData();
            }
          });
        }
      }
    },
    validatorInteger(rule, value, callback) {
      if (!value.length || !value.trim().length) {
        callback();
        return;
      }

      if (isNaN(Number.parseInt(value))) {
        callback(new Error("请输入大于0的整数"));
      }

      if (Number.isInteger(Number(value)) && Number(value) > 0) {
        callback();
      } else {
        callback(new Error("请输入大于0的整数"));
      }
    },
    validatorInteger0(rule, value, callback) {
      if (!value.length || !value.trim().length) {
        callback();
        return;
      }

      if (isNaN(Number.parseInt(value))) {
        callback(new Error("请输入大于或等于0的整数"));
      }

      if (Number.isInteger(Number(value)) && Number(value) >= 0) {
        callback();
      } else {
        callback(new Error("请输入大于或等于0的整数"));
      }
    },
    validatorInteger2(rule, value, callback) {
      if (!value.length || !value.trim().length) {
        callback();
        return;
      }

      if (isNaN(Number.parseInt(value))) {
        callback(new Error("请输入整数"));
      }

      if (Number.isInteger(Number(value))) {
        callback();
      } else {
        callback(new Error("请输入整数"));
      }
    },
    getDateTime(dataStr) {
      if (dataStr != null) {
        return new Date(dataStr);
      }

      return null;
    },
    setFormData() {
      // this.type = this.$route.query.type_id;
      // this.view_type = this.$route.query.view_type;

      //如果没拿到数据。则代表为新建
      if (
        JSON.stringify(this.match_data) != "{}" &&
        JSON.stringify(this.match_data) != undefined
      ) {
        if (this.match_data.length != 0) {
          let data = this.match_data;
          Object.keys(this.dataForm).forEach((key) => {
            if (data[key]) {
              this.dataForm[key] = data[key];
            }
          });

          this.dataForm.startTime = this.getDateTime(
            this.match_data.startTime || null
          );
          this.dataForm.endTime = this.getDateTime(
            this.match_data.endTime || null
          );

          this.org_rule = this.match_data.teamReward.reward;
          this.org_prize = this.match_data.personReword.reward;
          this.org_score = this.match_data.scoreRule.rule;
        }
      } else {
        this.initData();
      }
    },
    initData() {
      this.dataForm = {
        _id: "",
        startTime: null,
        endTime: null,
        step: "",

        reward_url: {
          reward: "",
          reward_url: "",
        },
        personReword: {
          reward: "",
          reward_url: "",
        },
        scoreRule: {
          rule: "",
          rule_url: "",
        },
      };
    },
    //返回上一次
    goBack() {
      this.$router.push(`/match/subject/list`);
    },
    submitForm() {
      this.$refs.dataForm.validate(async (valid, invalidFields) => {
        if (valid) {
          let d = this.getSaveData();
          if (this.org_rule != this.dataForm.teamReward.reward) {
            let reward_url = await this.uploadContent(
              "rule",
              this.dataForm.teamReward.reward
            );
            if (!reward_url || !reward_url.length) {
              this.$message.error("保存失败, 上传门派奖励失败");
              return;
            }

            d.teamReward.reward_url = reward_url;
          }

          if (this.org_prize != this.dataForm.personReword.reward) {
            let prize_url = await this.uploadContent(
              "prize",
              this.dataForm.personReword.reward
            );
            if (!prize_url || !prize_url.length) {
              this.$message.error("保存失败, 上传个人奖励失败");
              return;
            }

            d.personReword.reward_url = prize_url;
          }

          if (this.org_score != this.dataForm.scoreRule.rule) {
            let rule_url = await this.uploadContent(
              "score",
              this.dataForm.scoreRule.score
            );
            if (!rule_url || !rule_url.length) {
              this.$message.error("保存失败, 上传积分规则失败");
              return;
            }

            d.scoreRule.rule_url = rule_url;
          }
          d.match_type = this.match_type;
          let { data, errorCode } = await this.$http.match_subject.save_subject(
            d
          );
          if (errorCode != "0000") {
            this.$message.error("保存失败");
            return;
          }

          this.$message({
            type: "success",
            message: "保存成功",
          });

          this.removeTabByPath(`/match/subject/list`);
          this.$router.push(`/match/subject/list`);
          this.$bus.$emit("onMatchChanged", data);
        } else {
          console.log("error submit!!");
          console.log(invalidFields);
          let fileds = Object.keys(invalidFields);
          if (fileds.length) {
            let id1 = fileds[0];
            if (id1 == "desc") {
              id1 = "quillEditorQiniu";
            }

            if (document.getElementById(id1)) {
              document
                .getElementById(id1)
                .scrollIntoView({ behavior: "smooth" });
            }

            let refKey = id1.replace(/\./g, "_");
            if (this.$refs[refKey]) {
              this.$refs[refKey].focus();
            }
          }
          return false;
        }
      });
    },
    getSaveData() {
      let data = {
        ...this.dataForm,
      };

      console.log(data);
      return data;
    },
    resetForm() {
      this.$refs.dataForm.resetFields();
    },
    beforeTopImageUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG/PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }

      return isJPG && isLt2M;
    },

    async httpUploadEditorRequest2(item) {
      this.loading = true;
      let fileName = randomStr() + ".jpg";
      let path = uploadOssUrl().notice + fileName;
      let result = await this.$http.uploadFile(path, fileName, item.file);
      if (result) {
        console.log("上传成功2");
        // this.$emit('upload',res, file)
        // console.log(res, file);
        //拼接出上传的图片在服务器的完整地址
        let imgUrl = result;
        let type = imgUrl.substring(imgUrl.lastIndexOf(".") + 1);
        console.log(type);
        // 获取富文本组件实例
        let quill = this.$refs.personQuillEditorPrize.quill;
        // 获取光标所在位置
        let length = quill.getSelection().index;
        // 插入图片||视频  res.info为服务器返回的图片地址
        if (type == "mp4" || type == "MP4") {
          quill.insertEmbed(length, "video", imgUrl);
        } else {
          quill.insertEmbed(length, "image", imgUrl);
        }
        // 调整光标到最后
        quill.setSelection(length + 1);
        //取消上传动画
        this.quillUpdateImg = false;
      } else {
        this.$message.error("上传失败");
      }

      this.loading = false;
    },
    async httpUploadEditorRequest(item) {
      this.loading = true;
      let fileName = randomStr() + ".jpg";
      let path = uploadOssUrl().notice + fileName;
      let result = await this.$http.uploadFile(path, fileName, item.file);
      if (result) {
        console.log("上传成功0");
        // this.$emit('upload',res, file)
        // console.log(res, file);
        //拼接出上传的图片在服务器的完整地址
        let imgUrl = result;
        let type = imgUrl.substring(imgUrl.lastIndexOf(".") + 1);
        console.log(type);
        // 获取富文本组件实例
        let quill = this.$refs.customQuillEditor.quill;
        // 获取光标所在位置
        let length = quill.getSelection().index;
        // 插入图片||视频  res.info为服务器返回的图片地址
        if (type == "mp4" || type == "MP4") {
          quill.insertEmbed(length, "video", imgUrl);
        } else {
          quill.insertEmbed(length, "image", imgUrl);
        }
        // 调整光标到最后
        quill.setSelection(length + 1);
        //取消上传动画
        this.quillUpdateImg = false;
      } else {
        this.$message.error("上传失败");
      }

      this.loading = false;
    },
    async httpUploadEditorRequest1(item) {
      this.loading = true;
      let fileName = randomStr() + ".jpg";
      let path = uploadOssUrl().notice + fileName;

      let result = await this.$http.uploadFile(path, fileName, item.file);
      if (result) {
        console.log("上传成功1");
        // this.$emit('upload',res, file)
        // console.log(res, file);
        //拼接出上传的图片在服务器的完整地址
        let imgUrl = result;
        let type = imgUrl.substring(imgUrl.lastIndexOf(".") + 1);
        console.log(type);
        // 获取富文本组件实例
        let quill = this.$refs.customQuillEditorPrize.quill;
        // 获取光标所在位置
        let length = quill.getSelection().index;
        // 插入图片||视频  res.info为服务器返回的图片地址
        if (type == "mp4" || type == "MP4") {
          quill.insertEmbed(length, "video", imgUrl);
        } else {
          quill.insertEmbed(length, "image", imgUrl);
        }
        // 调整光标到最后
        quill.setSelection(length + 1);
        //取消上传动画
        this.quillUpdateImg = false;
      } else {
        this.$message.error("上传失败");
      }

      this.loading = false;
    },
    async uploadContent(t, d) {
      let blob = new Blob([d], { type: "text/html;charset=utf-8" });

      // 这么写是因为文件转换是异步任务
      let transToFile = async (blob, fileName, fileType) => {
        return new window.File([blob], fileName, {
          type: fileType,
          charset: "utf-8",
        });
      };

      let fileName = t + "_" + randomStr() + ".html";
      let textContain = transToFile(blob, fileName, "application/json");

      // 转换完成后可以将file对象传给接口
      return await new Promise((resolve, reject) => {
        textContain
          .then(async (res) => {
            let file = res;
            let path = uploadOssUrl().richText + this.type + "/" + fileName;
            let result = await this.$http.uploadFile(path, fileName, file);
            resolve(result);
          })
          .catch(() => {
            resolve(null);
          });
      });
    },
    cancelDialog() {},
    ...mapActions("tabs", [
      "handleTabsEdit",
      "addTabs",
      "handleTabsClick",
      "removeTab",
      "removeTabByPath",
    ]),
  },
};
</script>

<style lang="less" scoped>
.editor {
  line-height: normal !important;
  height: fit-content;
  width: 375px;
  padding: 0 !important;
}

/deep/.editor .ql-editor {
  height: 667px;
}

/*/deep/ .el-form-item__label {*/
/*  width: 150px!important;*/
/*}*/

/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
/deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
/deep/ .el-upload-dragger {
  width: 178px;
  height: 178px;
}
/deep/ .avatar {
  width: 178px;
  height: 178px;
  display: block;
}

/deep/ .el-upload-dragger {
  text-align: center;
}

/deep/ .el-upload-dragger img {
  margin: auto;
}

/deep/ #quillEditorQiniu .el-form-item__content {
  height: 100%;
}

/deep/ #quillEditorQiniuPrize .el-form-item__content {
  height: 100%;
}

/deep/ .ql-picker-item[data-value="custom-color"] {
  background: transparent !important;
  background-color: transparent !important;
  width: fit-content;
  height: fit-content;
}

/deep/ .ql-picker-item[data-value="custom-color"]::before {
  content: "自定义颜色";
  color: black;
  width: fit-content;
}
</style>
